import React, { useState } from 'react';

const AdmissionForm = () => {
    const [formData, setFormData] = useState({
        student_name: '',
        dob: '',
        class_admitted: '',
        father_name: '',
        mother_name: '',
        phone_no: '',
        address: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(
                'https://pixxeluclients.com/php-dev/risnagrota-backend/api/create-student-admission-form',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                }
            );

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(data.message || 'Form submitted successfully!');
                setErrorMessage('');
                setFormData({
                    student_name: '',
                    dob: '',
                    class_admitted: '',
                    father_name: '',
                    mother_name: '',
                    phone_no: '',
                    address: ''
                });
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message || 'Failed to submit the form.');
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage('An error occurred while submitting the form.');
            setSuccessMessage('');
        }
    };

    return (
        <div className='admission_form_devlop'>
            <div className='form_admission'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="student-name">Name of the Student:</label>
                        <input
                            type="text"
                            id="student-name"
                            name="student_name"
                            value={formData.student_name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='flex-field-ftt'>
                        <div className="form-group">
                            <label htmlFor="dob">Date of Birth:</label>
                            <input
                                type="date"
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="class_admitted">Class to be Admitted:</label>
                            <input
                                type="text"
                                id="class_admitted"
                                name="class_admitted"
                                value={formData.class_admitted}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className='flex-field-ftt'>
                        <div className="form-group">
                            <label htmlFor="father-name">Father's Name:</label>
                            <input
                                type="text"
                                id="father-name"
                                name="father_name"
                                value={formData.father_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mother-name">Mother's Name:</label>
                            <input
                                type="text"
                                id="mother-name"
                                name="mother_name"
                                value={formData.mother_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone_no">Phone Number:</label>
                        <input
                            type="number"
                            id="phone_no"
                            name="phone_no"
                            value={formData.phone_no}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <textarea
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit">Submit</button>
                    </div>
                     {successMessage && <p className="success-message">{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                </form>
               
            </div>
        </div>
    );
};

export default AdmissionForm;
